import { createContext } from 'react'
import { TranslatorFunctionResourcePropType } from '@jarvis/shell-commons/dist/services/localizationService/ILocalizationTranslatorService'
import { PromiseReturnType } from '../../types/typeHandlers'
import { LocalizationType } from '@jarvis/shell-commons/dist/interface/v1/orgSelector/types'
import { MfePropsType } from '../../types/mfeProps'
import { InterfacesType, TranslatorFunctionType } from '../../types/shell'
import { ToastReturn } from '@veneer/core/dist/scripts/toast_container/toast_context'

export type ShellRootContextType = {
  children?: React.ReactNode
  interfaces: InterfacesType
  t: TranslatorFunctionType
  useToast(): ToastReturn
  localizationResources?: TranslatorFunctionResourcePropType
} & MfePropsType

export type GlobalTranslateType = PromiseReturnType<
  LocalizationType['createTranslatorFunction']
>

export type ShellRootUseContextReturnType = ShellRootContextType & {
  globalTranslate: GlobalTranslateType
}

const ShellRootContext = createContext({} as ShellRootUseContextReturnType)

export default ShellRootContext
