import React, { useEffect, useState } from 'react'
import LanguageSelector from '../src/components/LanguageSelector'
import { InterfacesType, ShellRootComponentProps } from '../src/types/shell'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/shell'
import resources from '../src/assets/locale'
import ThemeProvider from '@veneer/theme/dist/theme_provider'
import { ShellRootProvider } from '../src/contexts/ShellRoot'
import DirectionProvider from '@veneer/core/dist/scripts/direction/direction_provider'

declare global {
  interface Window {
    Shell: InterfacesType
  }
}
export default function Root({
  // used only to test onboarding services
  useToast,
  properties
}: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>()
  const { v1, v2 } = window.Shell
  const interfaces = {
    v1,
    v2
  }

  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.()
  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React)

  const Component = LanguageSelector

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
  }, [v1])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  return (
    <section id={projectNames.packageJsonName}>
      <DirectionProvider direction={directionValue}>
        <ThemeProvider {...themeProviderProps}>
          <ShellRootProvider {...{ interfaces, t, useToast, properties }}>
            <Component {...{ ...properties, ...interfaces }} />
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  )
}
