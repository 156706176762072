import styled from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import tokens from '@veneer/tokens'

export const LanguageSelectorStyles = styled.div`
  width: 232px;
  > div:vn-select-1 {
    background-color: ${tokens.colorHpBlue2};
  }
`
